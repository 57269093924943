import axios from 'axios';
import { locale, addLocale} from 'primereact/api';

addLocale('es', {
    firstDayOfWeek: 1,
    dayNames: ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"],
    dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
    dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
    monthNames: ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", 
        "septiembre", "octubre", "noviembre", "diciembre"],
    monthNamesShort: ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"],
    today: 'Hoy',
    clear: 'Limpiar',
    dateFormat: 'yy-mm-dd',
    weekHeader: 'Sm'
});

locale('es');

export const yearRange = "1958:" + new Date().getFullYear();

export function toDate(dateStr) {
    // yyyy-mm-dd is the expected dateStr param format
    var parts = dateStr.split("-")
    return new Date(parts[0], parts[1] - 1, parts[2])
}

export function getFile(nombreArchivo) {
    if (nombreArchivo !== "") {
        try{
            axios({
                url: process.env.REACT_APP_API_URL + 'files/' + nombreArchivo,
                method: 'GET',
                responseType: 'blob', // important
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                // link.setAttribute('download', nombreArchivo);
                link.setAttribute("target", "_blank");
                document.body.appendChild(link);
                link.click();
            }).catch((response) => {
                return response.message;
            });
        }
        catch(e){
            console.log(e);
            return 'Error: ' + e;
        }
    } else {
        return 'No existe archivo asociado';
    }

    return '';
}
  
export function showFileLink(fileBlob) {
    if ( fileBlob.size > 0 ) {
        const url = window.URL.createObjectURL(new Blob([fileBlob],
            {type : 'application/pdf'}));
        const link = document.createElement('a');
        link.href = url;
        // link.setAttribute('download', nombreArchivo);
        link.setAttribute("target", "_blank");
        link.setAttribute("rel", "noopener noreferer");
        document.body.appendChild(link);
        link.click();
    } else {
        return 'No existe archivo asociado';
    }

    return '';
}