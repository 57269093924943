import React, { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import SearchComponent from '../dashboard/SearchComponent';
// import styles from './admin-norma.module.css';
import './admin-norma.css';

import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Toast } from 'primereact/toast';

import TablaResultados from '../tabla-resultados/TablaResultados';
import { logout } from '../../store/actions/userActions';
import manual from '../../assets/Administrador.pdf';

const AdminNorma = (props) => {
    const toast = useRef(null);
    const history = useHistory();
    const dispatch = useDispatch();

    const { listaNorma } = useSelector(
        state => ({
            listaNorma: state.listaNorma,
            loading: state.loading
        })
    );

    // eslint-disable-next-line no-unused-vars
    const showToast = (severityValue, summaryValue, detailValue) => {
        toast.current.show({ severity: severityValue, summary: summaryValue, detail: detailValue });
    }

    const newNormaOnClick = () => history.push('/admin-new-norma');
    const logoutOnClick = () => {
        dispatch(logout()).then(() => {
            history.push('/');
        });
    };

    const isResultados = listaNorma.listaNorma.length > 0 ? true : false;
    let resultados;
    if (isResultados) {
        resultados =
            <TablaResultados
                numRegistros="5" admin={true}
                listaNormas={listaNorma.listaNorma}
                tabla="ConsultaUsuario"
                paginador={listaNorma.pageOptions}>
            </TablaResultados>
    } else {
        resultados = <span style={{ color: "black" }}>Utilice la barra de búsqueda para consultar los
            documentos de su interés.</span>
    }

    const rightContents = (
        <React.Fragment>
            <Button label="Nueva norma" icon="pi pi-plus" className="p-button-primary"
                onClick={newNormaOnClick} />
            &nbsp;
            <Button label="Salir" icon="pi pi-sign-out" className="p-button-primary"
                onClick={logoutOnClick} />
        </React.Fragment>
    );
    return (
        <div>
            <Toast ref={toast} />
            <div className="admin-head">
                <h3 style={{ color: "black" }}>Administrador de Normas</h3>
                <a href={manual} rel='noreferrer' target='_blank' className="manual-admin">
                    <i className="pi pi-file-pdf"> Manual del Administrador</i>
                </a>
            </div>
            <Toolbar right={rightContents} />
            <SearchComponent administrador={true} />
            {resultados}
        </div>
    )
};

AdminNorma.propTypes = {};

AdminNorma.defaultProps = {};

export default AdminNorma;
