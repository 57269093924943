export const GET_TIPOS_NORMA = 'GET_TIPOS_NORMA';
export const TIPOS_NORMA_ERRORS = 'TIPOS_NORMA_ERRORS';

export const GET_ENTIDAD_EMISORA = 'GET_ENTIDAD_EMISORA';
export const ENTIDAD_EMISORA_ERRORS = 'ENTIDAD_EMISORA_ERRORS';

export const GET_TIPO_ASOCIACION_NORMA = 'GET_TIPO_ASOCIACION_NORMA';
export const TIPO_ASOCIACION_NORMA_ERRORS = 'TIPO_ASOCIACION_NORMA_ERRORS';

export const GET_LISTA_NORMA = 'GET_LISTA_NORMA';
export const LISTA_NORMA_ERRORS = 'LISTA_NORMA_ERRORS';

export const SAVE_LISTA_NORMA_PARAMS = 'SAVE_LISTA_NORMA_PARAMS';
export const SAVE_LISTA_NORMA_PARAMS_ERRORS = 'SAVE_LISTA_NORMA_PARAMS_ERRORS';

export const GET_NORMA_MAS_CONSULTADA = 'GET_NORMA_MAS_CONSULTADA';
export const NORMA_MAS_CONSULTADA_ERRORS = 'NORMA_MAS_CONSULTADA_ERRORS'

export const GET_NORMA_MAS_RECIENTE = 'GET_NORMA_MAS_RECIENTE';
export const NORMA_MAS_RECIENTE_ERRORS = 'NORMA_MAS_RECIENTE_ERRORS'

export const SAVE_NORMA = 'SAVE_NORMA';
export const NORMA_ERRORS = 'NORMA_ERRORS'
export const DELETE_NORMA= 'DELETE_NORMA'

export const SAVE_NORMA_FILE = 'SAVE_NORMA_FILE';
export const GET_NORMA_FILE = 'GET_NORMA_FILE';
export const NORMA_FILE_ERRORS = 'NORMA_FILE_ERRORS'

export const SET_SELECTED_NORMA = 'SET_SELECTED_NORMA';

export const GET_NORMA_ASOCIADA_LIST = 'GET_NORMA_ASOCIADA_LIST';
export const SET_NORMA_ASOC_LIST_AJU = 'SET_NORMA_ASOC_LIST_AJU';
export const NORMA_ASOCIADA_ERRORS = 'NORMA_ASOCIADA_ERRORS'
export const SAVE_NORMA_ASOCIADA = 'SAVE_NORMA_ASOCIADA'
export const DELETE_NORMA_ASOCIADA = 'DELETE_NORMA_ASOCIADA'
export const DELETE_NORMA_ASOCIADA_ERRORS = 'DELETE_NORMA_ASOCIADA_ERRORS'

export const USER_LOGIN = 'USER_LOGIN'
export const USER_LOGIN_ERROR = 'USER_LOGIN_ERROR'
export const GET_USER_ROLES = 'GET_USER_ROLES'
export const USER_ROLES_ERROR = 'USER_ROLES_ERROR'
export const USER_LOGOUT = 'USER_LOGOUT'